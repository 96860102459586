import { IUser } from "src/models/user.interface";
export class Api {
    authUser: IUser;

    _header:object

    tokenId:string;

    
    setAuthUser(authUser: IUser) {
        this.authUser = authUser;
      }
    
    getAuthUser() {
        return this.authUser;
    }
    
    setSessionUser(authUser: IUser) {
        sessionStorage.setItem('user', JSON.stringify(authUser));
    }
    
    getSessionUser():IUser {
        let userString = sessionStorage.getItem('user');
        let sessionUser: IUser = JSON.parse(userString)?.userData;
        return sessionUser;
    }

    destroySession(){
        sessionStorage.clear();
    }

    _headers = {
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${this.getSessionUser()?.token}`
        }
    };



}


